import { API } from './API';
import { AssessmentComponent } from './AssessmentComponent';
import { ResearchEntry } from './entities/ResearchEntry';
import {
  CONTENT,
  CONTENT_TEMPLATE,
  ERROR_TEMPLATE,
  LOADING,
  NO_RESEARCH_CONTENT_TEMPLATE,
} from './utils/DomConstants';
import { GetCandidateIdFromToken, GetTokenFromUrl } from './utils/UrlUtils';

interface IResearchValidation {
  init(): Promise<void>;
}

class ResearchValidation implements IResearchValidation {
  public static readonly instance = new ResearchValidation();

  private component: AssessmentComponent | undefined;

  /**
   * Get an assessment from the API, and either redirects or attaches
   * it to the DOM
   */
  public async init(): Promise<void> {
    const assessment = await this.getAssessmentFromAPI();
    if (!assessment) {
      return;
    }

    if (assessment.name === 'survey') {
      window.location.assign(assessment.url);
    } else {
      const token = GetTokenFromUrl(assessment.url);
      const cid = GetCandidateIdFromToken(token);
      localStorage.setItem('research_cid', cid);

      if (assessment.canEmbed) {
        this.component = new AssessmentComponent(assessment.url, assessment.name);
        await this.component.init();
      }

      CONTENT.appendChild(CONTENT_TEMPLATE);
      LOADING.style.display = 'none';
      CONTENT.querySelector('#play')?.addEventListener('click', () =>
        this.playAssessment(assessment)
      );
    }
  }

  private async getAssessmentFromAPI(): Promise<ResearchEntry | undefined> {
    try {
      return await API.instance.getAssessment();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e: any) {
      CONTENT.appendChild(NO_RESEARCH_CONTENT_TEMPLATE);
      LOADING.style.display = 'none';
    }
    return undefined;
  }

  private playAssessment(assessment: ResearchEntry) {
    // If it's a web component, attach it to the DOM
    if (this.component) {
      CONTENT.querySelector('#play')?.setAttribute('disabled', 'true');
      this.component.attachTo(CONTENT);
      this.component.addListeners();
      return;
    }

    // Otherwise redirect to assessment
    window.location.assign(assessment.url);
  }

  private showError(message: string) {
    CONTENT.appendChild(ERROR_TEMPLATE);
    LOADING.style.display = 'none';
    (CONTENT.querySelector('.error-message') as HTMLParagraphElement).innerText = message;
  }
}

ResearchValidation.instance.init();
