import { ResearchEntry } from './entities/ResearchEntry';

interface IAPI {
  getAssessment(id?: string): Promise<ResearchEntry>;
}

export class API implements IAPI {
  public static readonly instance = new API();

  public async getAssessment(id?: string): Promise<ResearchEntry> {
    // Here to support local development for now
    if (process.env.NODE_ENV === 'development') {
      // This is here so this data isn't bundled and imported by default. This way it can only be accessed
      // when running `yarn start` or setting NODE_ENV to development in build
      const TestData = await import('./utils/TestData');

      /**
       * If you want to test a specific assessment, pass in the name to this function, otherwise if left empty it will pick a random one
       * Some valid examples:
       * TestData.GetTestConfiguration('personality');
       * TestData.GetTestConfiguration('matchingfaces');
       * TestData.GetTestConfiguration();
       */
      const assessment = TestData.GetTestValidation('excel');
      return new Promise((resolve) => setTimeout(() => resolve(assessment), 2000));
    }
    const idPath = id ? `/${id}` : '';
    const res = await fetch(`/api/assessment${idPath}`);
    if (res.status !== 200) {
      throw new Error(`Failed to get an assessment - ${res.status}`);
    }
    const body = await res.json();
    return ResearchEntry.fromAPI(body);
  }
}
